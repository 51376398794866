import React from "react";
import UnderConstruction from "../../Unauthorized/UnderConstruction";

function Items() {
  return (
    <div>
      <UnderConstruction />
    </div>
  );
}

export default Items;
