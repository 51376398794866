import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import styles from "./ForgetPassword.module.css";
import { forgotPassword, reset } from "../../../Features/UserSlice";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();

  const { isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.user
  );

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await dispatch(forgotPassword(email)).unwrap();
    } catch (error) {
      console.error("Password reset error:", error);
    }
  };

  return (
    <div className={styles.loginContainer}>
      <div className={styles.loginCard}>
        <div className={styles.cardHeader}>
          <h3 className={styles.cardTitle}>
            <i className="bi bi-key me-2"></i>
            Reset Password
          </h3>
        </div>
        <div className={styles.cardBody}>
          <div className={styles.forgotPasswordInfo}>
            <p className={styles.infoText}>
              Enter your email address and we'll send you instructions to reset
              your password.
            </p>
          </div>

          {message && (
            <div
              className={`${styles.alert} ${
                isError ? styles.alertError : styles.alertSuccess
              }`}
            >
              <i
                className={`bi ${
                  isError ? "bi-exclamation-circle" : "bi-check-circle"
                } me-2`}
              ></i>
              {message}
            </div>
          )}

          <form onSubmit={handleSubmit}>
            <div className={styles.formGroup}>
              <label htmlFor="email" className={styles.label}>
                Email Address
              </label>
              <div className={styles.inputGroup}>
                <i className={`bi bi-envelope ${styles.inputIcon}`}></i>
                <input
                  type="email"
                  id="email"
                  className={styles.input}
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={isLoading || isSuccess}
                  required
                />
              </div>
            </div>

            <button
              type="submit"
              className={styles.submitButton}
              disabled={isLoading || !email || isSuccess}
            >
              {isLoading ? (
                <div className="d-flex align-items-center justify-content-center">
                  <span
                    className="spinner-border spinner-border-sm me-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Sending...
                </div>
              ) : (
                <>
                  <i className="bi bi-send me-2"></i>
                  Send Reset Link
                </>
              )}
            </button>

            <div className={styles.links}>
              <p>
                Remember your password?{" "}
                <Link to="/login" className={styles.link}>
                  Back to Login
                </Link>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
