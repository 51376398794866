import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const axiosInstance = axios.create({
  baseURL: apiUrl,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.request.use((request) => {
  // console.log("Request Cookies:", document.cookie);
  return request;
});

axiosInstance.interceptors.response.use(
  (response) => {
    // console.log("Response Cookies:", document.cookie);
    return response;
  },
  (error) => {
    console.log("Error Status:", error.response?.status);
    console.log("Error Data:", error.response?.data);
    return Promise.reject(error);
  }
);

export default axiosInstance;
