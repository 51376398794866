import React, { useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginUser, reset } from "../../../Features/UserSlice";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoginValidations } from "../../../Validations/LoginValidations";
import styles from "./Login.module.css";
import { showSuccessToast } from "../../../utils/Toastr";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isLoading, isError, message, isAuthenticated } = useSelector(
    (state) => state.user
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(LoginValidations),
  });

  useEffect(() => {
    dispatch(reset());
  }, [dispatch]);

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/Dashboard");
    }
  }, [isAuthenticated, navigate]);

  const onSubmit = async (data) => {
    try {
      const result = await dispatch(loginUser(data)).unwrap();
      if (result && (result.user || result)) {
        showSuccessToast("Logged in successfully");
        navigate("/Dashboard");
      }
    } catch (error) {
      console.error("Login Error:", error);
    }
  };

  document.title = "Login";

  return (
    <div className={styles.loginContainer}>
      <div className={styles.loginCard}>
        <div className={styles.cardHeader}>
          <h3 className={styles.cardTitle}>Login</h3>
        </div>
        <div className={styles.cardBody}>
          {message && (
            <div
              className={`${styles.alert} ${
                isError ? styles.alertError : styles.alertSuccess
              }`}
            >
              {message}
            </div>
          )}

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.formGroup}>
              <label htmlFor="email" className={styles.label}>
                Email Address
              </label>
              <div className={styles.inputGroup}>
                <i className={`bi bi-envelope ${styles.inputIcon}`}></i>
                <input
                  type="email"
                  className={`${styles.input} ${
                    errors.email ? styles.error : ""
                  }`}
                  id="email"
                  placeholder="Enter your email"
                  {...register("email")}
                  disabled={isLoading}
                />
                {errors.email && (
                  <div className={styles.errorMessage}>
                    {errors.email.message}
                  </div>
                )}
              </div>
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="password" className={styles.label}>
                Password
              </label>
              <div className={styles.inputGroup}>
                <i className={`bi bi-lock ${styles.inputIcon}`}></i>
                <input
                  type="password"
                  className={`${styles.input} ${
                    errors.password ? styles.error : ""
                  }`}
                  id="password"
                  placeholder="Enter your password"
                  {...register("password")}
                  disabled={isLoading}
                />
                {errors.password && (
                  <div className={styles.errorMessage}>
                    {errors.password.message}
                  </div>
                )}
              </div>
            </div>

            <button
              type="submit"
              className={styles.button}
              disabled={isLoading}
            >
              {isLoading ? (
                <>
                  <span
                    className="spinner-border spinner-border-sm me-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Logging in...
                </>
              ) : (
                "Login"
              )}
            </button>

            <div className={styles.links}>
              <p>
                Don't have an account?{" "}
                <Link to="/register" className={styles.link}>
                  Register
                </Link>
              </p>
              <Link to="/forgot-password" className={styles.forgotPassword}>
                Forgot Password?
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
