import "./App.css";
import Login from "./Components/auth/Login/Login";
import OTP from "./Components/auth/Register/OTP";
import Register from "./Components/auth/Register/Register";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Components/Home/Home";
import ProtectedRoute from "./utils/ProtectedRoute";
import ForgotPassword from "./Components/auth/ForgetPassword/ForgetPassword";
import Dashboard from "./Components/Dashboard/Dashboard";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Profile from "./Components/Dashboard/Profile/Profile";
import Category from "./Components/Dashboard/Category/Category";
import Categories from "./Components/Categories/Categories";
import MainLayout from "./Components/layouts/MainLayout";
import DashboardLayout from "./Components/Dashboard/DashboardLayout";
import Unauthorized from "./Components/Unauthorized/Unauthorized";
import Items from "./Components/Dashboard/Items/Items";
import Hsitory from "./Components/Dashboard/History/Hsitory";
import WebPages from "./Components/Dashboard/WebPages/WebPages";
import Report from "./Components/Dashboard/reports/Report";
import Users from "./Components/Dashboard/Users/Users";
import UnderConstruction from "./Components/Unauthorized/UnderConstruction";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route element={<MainLayout />}>
            <Route index element={<Home />} />{" "}
            <Route path="/categories" element={<Categories />} />
            <Route path="/underconstruction" element={<UnderConstruction />} />
          </Route>

          <Route path="/register" element={<Register />} />
          <Route path="/otp" element={<OTP />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/unauthorized" element={<Unauthorized />} />

          {/* Dashboard Routes - Protected */}
          <Route
            element={
              <ProtectedRoute allowedRoles={["user", "admin"]}>
                <DashboardLayout />
              </ProtectedRoute>
            }
          >
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/dashboard/profile" element={<Profile />} />
            <Route path="/dashboard/history" element={<Hsitory />} />
          </Route>

          {/* Admin Routes - Protected */}
          <Route
            element={
              <ProtectedRoute allowedRoles={["admin"]}>
                <DashboardLayout />
              </ProtectedRoute>
            }
          >
            <Route path="/dashboard/category" element={<Category />} />
            <Route path="/dashboard/items" element={<Items />} />
            <Route path="/dashboard/web-pages" element={<WebPages />} />
            <Route path="/dashboard/reports" element={<Report />} />
            <Route path="/dashboard/users" element={<Users />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
