import React from "react";
import styles from "../Dashboard.module.css";
import productImage from "../../../images/seed2.jpg";
export const LastProduct = () => {
  return (
    <div className={styles.productCard}>
      <div className={styles.cardHeader}>
        <div className={styles.headerText}>
          <h3>Last Product</h3>
          <span className={styles.productCount}>52 products</span>
        </div>
        <div className={styles.badge}>Save 25%</div>
      </div>

      <div className={styles.productImage}>
        <img src={productImage} alt="Sarpan Seeds" className={styles.image} />
      </div>
    </div>
  );
};
