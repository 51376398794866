import React, { useState, useEffect } from "react";
import styles from "./Category.module.css";
import { showErrorToast, showSuccessToast } from "../../../utils/Toastr";
import {
  clearSelectedCategory,
  createCategory,
  deleteCategory,
  getAllCategories,
  reset,
  setSelectedCategory,
  updateCategory,
  uploadCategoryImage,
} from "../../../Features/CategorySlice";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Category = () => {
  const dispatch = useDispatch();
  const {
    categories,
    isLoading,
    isSuccess,
    isError,
    message,
    selectedCategory,
  } = useSelector((state) => state.category);

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    featured: false,
    image: "",
  });
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    dispatch(getAllCategories());
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      showErrorToast(message);
    }

    if (isSuccess && message) {
      showSuccessToast(message);
    }

    dispatch(reset());
  }, [isError, isSuccess, message, dispatch]);

  useEffect(() => {
    if (selectedCategory) {
      setFormData(selectedCategory);
    }
  }, [selectedCategory]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      let imageUrl = formData.image;
      if (selectedFile) {
        const uploadResponse = await dispatch(
          uploadCategoryImage(selectedFile)
        ).unwrap();
        imageUrl = uploadResponse.img;
      }

      if (selectedCategory) {
        await dispatch(
          updateCategory({
            id: selectedCategory._id,
            categoryData: { ...formData, image: imageUrl },
          })
        ).unwrap();
      } else {
        await dispatch(
          createCategory({ ...formData, image: imageUrl })
        ).unwrap();
      }

      setFormData({
        name: "",
        description: "",
        featured: false,
        image: "",
      });
      setSelectedFile(null);
      dispatch(clearSelectedCategory());
      dispatch(getAllCategories());
    } catch (error) {
      showErrorToast(error);
    }
  };

  const handleEdit = (category) => {
    dispatch(setSelectedCategory(category));
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this category?")) {
      try {
        await dispatch(deleteCategory(id)).unwrap();
        dispatch(getAllCategories());
      } catch (error) {
        showErrorToast(error);
      }
    }
  };

  return (
    <div className={`${styles.layout} container-fluid py-4`}>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/Dashboard" className="text-decoration-none">
              Home
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Category
          </li>
        </ol>
      </nav>
      <div className="row">
        {/* Category Form */}
        <div className="col-12 col-lg-4 mb-4">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title mb-4">
                {/* {isEditing ? "Edit Category" : "Add New Category"} */}
              </h5>
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label className="form-label">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Description</label>
                  <textarea
                    className="form-control"
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    required
                    rows="3"
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Image</label>
                  <input
                    type="file"
                    className="form-control"
                    onChange={handleFileChange}
                    accept="image/*"
                  />
                </div>
                <div className="mb-3 form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="featured"
                    checked={formData.featured}
                    onChange={handleInputChange}
                  />
                  <label className="form-check-label">Featured Category</label>
                </div>
                {/* Update*/}
                <div className="d-grid">
                  <button
                    type="submit"
                    className="btn btn-success"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : selectedCategory ? (
                      "Update Category"
                    ) : (
                      "Add Category"
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* Categories List */}
        <div className="col-12 col-lg-8">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title mb-4">Categories</h5>
              {isLoading && !categories.length ? (
                <div className="text-center py-4">
                  <div className="spinner-border text-success" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
                <div className="table-responsive">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th>Image</th>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Featured</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {categories.map((category) => (
                        <tr key={category._id}>
                          <td>
                            <img
                              src={`${
                                process.env.REACT_APP_API_URL.split(
                                  "/api/v1"
                                )[0]
                              }${category.image}
                              `}
                              alt={category.name}
                              className={styles.categoryImage}
                            />
                          </td>
                          <td>{category.name}</td>
                          <td>
                            {category.description.length > 50
                              ? `${category.description.substring(0, 50)}...`
                              : category.description}
                          </td>
                          <td>
                            <span
                              className={`badge ${
                                category.featured
                                  ? "bg-success"
                                  : "bg-secondary"
                              }`}
                            >
                              {category.featured ? "Yes" : "No"}
                            </span>
                          </td>
                          <td>
                            <button
                              className="btn btn-sm btn-outline-primary me-2"
                              onClick={() => handleEdit(category)}
                            >
                              <i className="fas fa-edit"></i>
                            </button>
                            <button
                              className="btn btn-sm btn-outline-danger"
                              onClick={() => handleDelete(category._id)}
                            >
                              <i className="fas fa-trash"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Category;
