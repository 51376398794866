import React from "react";

const Unauthorized = () => {
  return (
    <div className="d-flex min-vh-100 align-items-center justify-content-center bg-light">
      <div className="bg-white shadow rounded p-4 text-center">
        <div className="d-flex justify-content-center mb-4">
          <div className="bg-danger p-3 rounded-circle">
            <i className="fa fa-lock text-white" style={{ fontSize: "2rem" }} />
          </div>
        </div>
        <h1 className="h4 font-weight-bold text-dark mb-2">Access Denied</h1>
        <p className="text-muted mb-4">
          You don't have permission to access this page.
        </p>

        <button
          className="btn btn-primary"
          onClick={() => (window.location.pathname = "/")}
        >
          Go Back
        </button>
      </div>
    </div>
  );
};

export default Unauthorized;
