import React from "react";
import Hero from "./Hero/Hero";
import ShowCaseItems from "./itemShowCase/ShowCaseItems";
import Cta from "./cta/Cta";

const Home = () => {
  return (
    <>
      <Hero />
      <ShowCaseItems />
      <Cta />
    </>
  );
};

export default Home;
