import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import styles from "../Dashboard.module.css";

const data = [
  { month: "Oct 2024", achieved: 6, target: 3 },
  { month: "Nov 2024", achieved: 6.5, target: 4 },
  { month: "Dec 2024", achieved: 4, target: 4.5 },
  { month: "Jan 2024", achieved: 8, target: 5 },
  { month: "Feb 2024", achieved: 7, target: 4 },
  { month: "Mar 2024", achieved: 7.5, target: 3.5 },
];

export const IncomeChart = () => {
  return (
    <div className={styles.chartCard}>
      <div className={styles.chartHeader}>
        <div className={styles.headerText}>
          <h3>Income</h3>
          <select className={styles.timeSelect}>
            <option value="this-week">This Week</option>
            <option value="this-month">This Month</option>
            <option value="this-year">This Year</option>
          </select>
        </div>
        <div className={styles.legend}>
          <div className={styles.legendItem}>
            <span className={styles.dotAchieved}></span>
            <span>7 Items</span>
          </div>
          <div className={styles.legendItem}>
            <span className={styles.dotTarget}></span>
            <span>5 Items</span>
          </div>
        </div>
      </div>

      <div className={styles.chartContainer}>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={data}>
            <XAxis dataKey="month" />
            <YAxis />
            <Tooltip />
            <Line
              type="monotone"
              dataKey="achieved"
              stroke="#FF6B6B"
              strokeWidth={2}
              dot={{ fill: "#FF6B6B" }}
            />
            <Line
              type="monotone"
              dataKey="target"
              stroke="#4C7C4C"
              strokeWidth={2}
              dot={{ fill: "#4C7C4C" }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};
