import React from "react";
import UnderConstruction from "../../Unauthorized/UnderConstruction";

function Report() {
  return (
    <div>
      <UnderConstruction />
    </div>
  );
}

export default Report;
