import * as yup from "yup";

// Password regex: at least 10 characters long, contains at least one uppercase letter, and one symbol
const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{10,})/;

// Email regex
const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const RegistrationValidations = yup.object().shape({
  username: yup.string().required("Name cannot be empty").min(3),
  email: yup
    .string()
    .required("Email cannot be empty")
    .matches(emailRegex, "Incorrect Email Format"),

  password: yup
    .string()
    .required("Password cannot be empty")
    .matches(
      passwordRegex,
      "Password must be at least 10 characters long, contain at least one uppercase letter, and one symbol"
    )
    .min(10, "Password must be at least 10 characters long"),

  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password cannot be empty"),
});
