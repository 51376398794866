export const allNavItems = [
  {
    path: "/dashboard",
    icon: "fas fa-chart-pie",
    label: "Dashboard",
    end: true,
    allowedRoles: ["user", "admin"],
  },
  {
    path: "/dashboard/category",
    icon: "fas fa-folder",
    label: "Category",
    allowedRoles: ["admin"],
  },
  {
    path: "/dashboard/items",
    icon: "fas fa-box",
    label: "Items",
    allowedRoles: ["admin"],
  },
  {
    path: "/dashboard/history",
    icon: "fas fa-history",
    label: "History",
    allowedRoles: ["user", "admin"],
  },
  {
    path: "/dashboard/web-pages",
    icon: "fas fa-globe",
    label: "Web Pages",
    allowedRoles: ["admin"],
  },
  {
    path: "/dashboard/reports",
    icon: "fas fa-file-alt",
    label: "Reports",
    allowedRoles: ["admin"],
  },
  {
    path: "/dashboard/users",
    icon: "fas fa-users",
    label: "Users",
    allowedRoles: ["admin"],
  },
];
