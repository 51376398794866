import React from "react";
import styles from "./Cta.module.css";
import ctaImage from "../../../images/cta.png";

const Cta = () => {
  return (
    <section className={styles.ctaSection}>
      <div className="container">
        <div className="row position-relative">
          <div className="col-lg-6">
            <div className={styles.ctaContent}>
              <h2 className="display-4 fw-bold mb-4">
                Bloom with Our Stunning Flowers
              </h2>
              <p className="lead mb-4">
                Explore our exquisite collection of flowers that add a touch of
                elegance to any space. From vibrant bouquets to delicate
                arrangements, our flowers are perfect for every occasion
              </p>
              <button className="btn btn-outline-success btn-lg px-4">
                Discover Now
              </button>
            </div>
          </div>

          <div className="col-lg-6 position-relative">
            <div className={styles.mainPlantImage}>
              <img
                src={ctaImage}
                alt="Decorative plant"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Cta;
