// Register.jsx
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { registerUser } from "../../../Features/UserSlice"; // You'll need to create this
import { RegistrationValidations } from "../../../Validations/RegisterValidations";
import styles from "../Login/Login.module.css";

const Register = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const msg = useSelector((state) => state.user.message);
  const loading = useSelector((state) => state.user.isLoading);

  // Local state for form values
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  // Initialize react-hook-form with yup validation
  const {
    register,
    handleSubmit: submitForm,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(RegistrationValidations),
  });

  // Handle form submission
  const handleSubmit = () => {
    dispatch(
      registerUser({
        username: name,
        email: email,
        password: password,
      })
    )
      .unwrap()
      .then(() => {
        navigate("/otp", { state: { email: email } });
      })
      .catch((error) => {
        console.error("Registration failed: ", error);
      });
  };

  document.title = "Register";

  return (
    <div className={styles.loginContainer}>
      <div className={styles.loginCard}>
        <div className={styles.cardHeader}>
          <h3 className={styles.cardTitle}>Create Account</h3>
        </div>
        <div className={styles.cardBody}>
          {msg && (
            <div
              className={`${styles.alert} ${
                msg.includes("success")
                  ? styles.alertSuccess
                  : styles.alertError
              }`}
            >
              {msg}
            </div>
          )}

          <form>
            <div className={styles.formGroup}>
              <label className={styles.label}>Username</label>
              <div className={styles.inputGroup}>
                <i className={`bi bi-person ${styles.inputIcon}`}></i>
                <input
                  type="text"
                  className={`${styles.input} ${
                    errors.username ? styles.error : ""
                  }`}
                  placeholder="Enter your username"
                  {...register("username", {
                    onChange: (e) => setName(e.target.value),
                  })}
                  value={name}
                />
                {errors.username && (
                  <div className={styles.errorMessage}>
                    {errors.username.message}
                  </div>
                )}
              </div>
            </div>

            <div className={styles.formGroup}>
              <label className={styles.label}>Email Address</label>
              <div className={styles.inputGroup}>
                <i className={`bi bi-envelope ${styles.inputIcon}`}></i>
                <input
                  type="email"
                  className={`${styles.input} ${
                    errors.email ? styles.error : ""
                  }`}
                  placeholder="Enter your email"
                  {...register("email", {
                    onChange: (e) => setEmail(e.target.value),
                  })}
                  value={email}
                />
                {errors.email && (
                  <div className={styles.errorMessage}>
                    {errors.email.message}
                  </div>
                )}
              </div>
            </div>

            <div className={styles.formGroup}>
              <label className={styles.label}>Password</label>
              <div className={styles.inputGroup}>
                <i className={`bi bi-lock ${styles.inputIcon}`}></i>
                <input
                  type="password"
                  className={`${styles.input} ${
                    errors.password ? styles.error : ""
                  }`}
                  placeholder="Enter your password"
                  {...register("password", {
                    onChange: (e) => setPassword(e.target.value),
                  })}
                  value={password}
                />
                {errors.password && (
                  <div className={styles.errorMessage}>
                    {errors.password.message}
                  </div>
                )}
              </div>
            </div>

            <div className={styles.formGroup}>
              <label className={styles.label}>Confirm Password</label>
              <div className={styles.inputGroup}>
                <i className={`bi bi-lock-fill ${styles.inputIcon}`}></i>
                <input
                  type="password"
                  className={`${styles.input} ${
                    errors.confirmPassword ? styles.error : ""
                  }`}
                  placeholder="Confirm your password"
                  {...register("confirmPassword", {
                    onChange: (e) => setConfirmPassword(e.target.value),
                  })}
                  value={confirmPassword}
                />
                {errors.confirmPassword && (
                  <div className={styles.errorMessage}>
                    {errors.confirmPassword.message}
                  </div>
                )}
              </div>
            </div>

            <button
              type="button"
              className={styles.button}
              onClick={submitForm(handleSubmit)}
              disabled={loading}
            >
              {loading ? (
                <>
                  <span
                    className="spinner-border spinner-border-sm me-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Creating Account...
                </>
              ) : (
                "Create Account"
              )}
            </button>

            <div className={styles.links}>
              <p>
                Already have an account?{" "}
                <Link to="/login" className={styles.link}>
                  Login
                </Link>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Register;
