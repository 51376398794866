import React, { useState } from "react";
import Sidebar from "./Sidebar/Sidebar";
import { LastProduct } from "./Components/LastProduct";
import { IncomeChart } from "./Components/IncomeChart";
import { OrdersTable } from "./Components/OrdersTable";
import styles from "./Dashboard.module.css";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../Features/UserSlice";






const Dashboard = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [showDropdown, setShowDropdown] = useState(false);
  const dispatch = useDispatch();

  const handleSidebarToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleLogout = () => {
    dispatch(logoutUser());
  };
  const { user } = useSelector((state) => state.user);

  return (
    <>
      <div className={styles.layout}>
        <div
          className={`${styles.mainContent} ${
            !isSidebarOpen ? styles.expanded : ""
          }`}
        >
          <div className={styles.dashboardContainer}>
            <div className={styles.header}>
              <h1 className={styles.title}>Dashboard</h1>
              <div className={styles.userMenuContainer}>
                <button className={styles.gridButton} onClick={toggleDropdown}>
                  <i className="fas fa-user"></i>
                </button>
                {showDropdown && (
                  <div className={styles.dropdownMenu}>
                    <ul>
                      <li>
                        <a href="/dashboard/profile">Profile</a>
                      </li>
                      {/* <li>
                        <a href="/settings">Settings</a>
                      </li> */}
                      <li onClick={handleLogout}>
                        <span className={`${styles.logout}`}>Sign out</span>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>

            {user.role === "admin" && (
              <div className={styles.statsGrid}>
                <LastProduct />
                <IncomeChart />
              </div>
            )}

            <div className={styles.ordersSection}>
              <h2 className={styles.sectionTitle}>Latest Orders</h2>
              <OrdersTable />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
