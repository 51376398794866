// Features/UserSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from "../utils/axiosConfig";

const apiUrl = process.env.REACT_APP_API_URL;

// Get user from localStorage
const user = localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user"))
  : null;

// Register user and get OTP
export const registerUser = createAsyncThunk(
  "user/register",
  async (userData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${apiUrl}/auth/register`, {
        name: userData.username,
        email: userData.email,
        password: userData.password,
      });
      localStorage.setItem(
        "pendingRegistration",
        JSON.stringify({
          email: userData.email,
          name: userData.username,
        })
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.msg || "Registration failed"
      );
    }
  }
);

// Verify OTP
export const verifyOTP = createAsyncThunk(
  "user/verifyOTP",
  async (otpData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`${apiUrl}/auth/verifyOTP`, {
        email: otpData.email,
        otp: otpData.otp,
      });
      // Clear pending registration data after successful verification
      localStorage.removeItem("pendingRegistration");
      // Save verified user data
      if (response.data.user) {
        localStorage.setItem("user", JSON.stringify(response.data.user));
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.msg || "Invalid OTP");
    }
  }
);

// Login user
export const loginUser = createAsyncThunk(
  "user/login",
  async (userData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`${apiUrl}/auth/login`, {
        email: userData.email,
        password: userData.password,
      });

      // console.log("Login Response:", response.data);

      if (!response.data) {
        return rejectWithValue("No data received from server");
      }

      if (response.data.user) {
        localStorage.setItem("user", JSON.stringify(response.data.user));
      }

      return response.data;
    } catch (error) {
      // console.log("Login Error:", error.response?.data);
      return rejectWithValue(
        error.response?.data?.msg || "Invalid credentials"
      );
    }
  }
);

// Logout user
export const logoutUser = createAsyncThunk(
  "user/logout",
  async (_, { rejectWithValue }) => {
    try {
      await axiosInstance.get(`${apiUrl}/auth/logout`, {
        withCredentials: true,
      });

      localStorage.removeItem("user");
      localStorage.removeItem("token");
      return null;
    } catch (error) {
      return rejectWithValue(error.response?.data?.msg || "Logout failed");
    }
  }
);

// forget password
export const forgotPassword = createAsyncThunk(
  "user/forgotPassword",
  async (email, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${apiUrl}/auth/forgotPassword`, {
        email,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.msg || "Password reset request failed"
      );
    }
  }
);

// update password
export const updatePassword = createAsyncThunk(
  "user/updateUserPassword",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.patch(
        `${apiUrl}/users/updateUserPassword`,
        {
          oldPassword: data.oldPassword,
          newPassword: data.newPassword,
        }
      );
      return response.data;
    } catch (error) {
      // console.log("Password Update Error:", error.response?.data);

      return rejectWithValue(
        error.response?.data?.msg || "Password update failed"
      );
    }
  }
);

// update user
export const updateUser = createAsyncThunk(
  "user/updateUser",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.patch(`${apiUrl}/users/updateUser`, {
        name: data.name,
      });
      const currentUser = JSON.parse(localStorage.getItem("user"));
      const updatedUser = { ...currentUser, name: data.name };
      localStorage.setItem("user", JSON.stringify(updatedUser));

      return { ...response.data, user: updatedUser };
    } catch (error) {
      return rejectWithValue(error.response?.data?.msg || "User update failed");
    }
  }
);

// update avatar
export const updateAvatar = createAsyncThunk(
  "user/updateAvatar",
  async (file, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append("avatar", file);

      const response = await axiosInstance.patch(
        `${apiUrl}/users/updateAvatar`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Update local storage
      const currentUser = JSON.parse(localStorage.getItem("user"));
      const updatedUser = { ...currentUser, avatar: response.data.user.avatar };
      localStorage.setItem("user", JSON.stringify(updatedUser));

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.msg || "Avatar update failed"
      );
    }
  }
);

const initialState = {
  user: JSON.parse(localStorage.getItem("user")) || null,
  isAuthenticated: !!localStorage.getItem("user"),
  userId: user?.id || null,
  message: "",
  isLoading: false,
  isSuccess: false,
  isError: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
    clearUserState: (state) => {
      state.user = null;
      state.userId = null;
      state.isAuthenticated = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
      localStorage.removeItem("user");
      localStorage.removeItem("pendingRegistration");
    },
  },
  extraReducers: (builder) => {
    builder
      // Register cases
      .addCase(registerUser.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.message = "";
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload.message;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      // Verify OTP cases
      .addCase(verifyOTP.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.message = "";
      })
      .addCase(verifyOTP.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload.user;
        state.userId = action.payload.user?.id;
        state.isAuthenticated = true;
        state.message = "Email verified successfully";
      })
      .addCase(verifyOTP.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      // Login cases
      .addCase(loginUser.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.message = "";
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isAuthenticated = true;
        state.user = action.payload.user;
        state.userId = action.payload.user?.id;
        state.message = "Login successful";
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.isAuthenticated = false;
        state.user = null;
        state.userId = null;
      })

      // Logout cases
      .addCase(logoutUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = false;
        state.message = "";
        state.user = null;
        state.userId = null;
        state.isAuthenticated = false;
      })
      .addCase(logoutUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      // Forgot Password cases
      .addCase(forgotPassword.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.message = "";
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload.message;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      // update password cases
      .addCase(updatePassword.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.message = "";
      })
      .addCase(updatePassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload.msg;
      })
      .addCase(updatePassword.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      // update user cases
      .addCase(updateUser.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.message = "";
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload.msg;
        state.user = action.payload.user;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

      // Update Avatar cases
      .addCase(updateAvatar.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.message = "";
      })
      .addCase(updateAvatar.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload.msg;
        state.user = action.payload.user;
      })
      .addCase(updateAvatar.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset, clearUserState } = userSlice.actions;
export default userSlice.reducer;
