import React from "react";
import { Link } from "react-router-dom";
import logo from "../../../images/logo brown.png";

const Footer = () => {
  return (
    <footer style={{ backgroundColor: "#4D7745" }} className="py-5 text-white">
      <div className="container">
        {/* Main Footer Content */}
        <div className="row g-4">
          {/* Logo and Description Column */}
          <div className="col-lg-4 pe-lg-5">
            <Link
              to="/"
              className="d-flex align-items-center mb-3 text-decoration-none"
            >
              <img src={logo} alt="Nabat Tech" height="40" className="me-2" />
              <div>
                <span className="text-white fw-bold">Nabat</span>
                <span className="text-white-50">Tech</span>
              </div>
            </Link>
            <p className="mb-4">Bringing Nature's Beauty to Your Doorstep</p>
            <div className="d-flex gap-3 mb-4">
              <Link to="/" className="text-white">
                <i className="fab fa-facebook-f"></i>
              </Link>
              <Link to="/" className="text-white">
                <i className="fab fa-youtube"></i>
              </Link>
              <Link to="/" className="text-white">
                <i className="fab fa-instagram"></i>
              </Link>
              <Link to="/" className="text-white">
                <i className="fab fa-tiktok"></i>
              </Link>
              <Link to="/" className="text-white">
                <i className="fab fa-whatsapp"></i>
              </Link>
              <Link to="/" className="text-white">
                <i className="fab fa-snapchat"></i>
              </Link>
            </div>
          </div>

          {/* Customer Service Column */}
          <div className="col-sm-6 col-lg-2">
            <h5 style={{ color: "#D4BC8B" }} className="mb-3">
              Customer Service
            </h5>
            <ul className="list-unstyled mb-0">
              <li className="mb-2">
                <Link to="/contact" className="text-white text-decoration-none">
                  Contact Us
                </Link>
              </li>
              <li className="mb-2">
                <Link to="/faq" className="text-white text-decoration-none">
                  FAQ
                </Link>
              </li>
              <li className="mb-2">
                <Link
                  to="/shipping"
                  className="text-white text-decoration-none"
                >
                  Shipping & Returns
                </Link>
              </li>
              <li className="mb-2">
                <Link
                  to="/tracking"
                  className="text-white text-decoration-none"
                >
                  Order Tracking
                </Link>
              </li>
            </ul>
          </div>

          {/* Products Column */}
          <div className="col-sm-6 col-lg-2">
            <h5 style={{ color: "#D4BC8B" }} className="mb-3">
              Products
            </h5>
            <ul className="list-unstyled mb-0">
              <li className="mb-2">
                <Link to="/flowers" className="text-white text-decoration-none">
                  Flowers
                </Link>
              </li>
              <li className="mb-2">
                <Link to="/plants" className="text-white text-decoration-none">
                  Plants
                </Link>
              </li>
              <li className="mb-2">
                <Link
                  to="/fertilizers"
                  className="text-white text-decoration-none"
                >
                  Fertilizers
                </Link>
              </li>
              <li className="mb-2">
                <Link to="/tools" className="text-white text-decoration-none">
                  Gardening Tools
                </Link>
              </li>
            </ul>
          </div>

          {/* About Us Column */}
          <div className="col-sm-6 col-lg-2">
            <h5 style={{ color: "#D4BC8B" }} className="mb-3">
              About Us
            </h5>
            <ul className="list-unstyled mb-0">
              <li className="mb-2">
                <Link
                  to="/our-story"
                  className="text-white text-decoration-none"
                >
                  Our Story
                </Link>
              </li>
              <li className="mb-2">
                <Link to="/mission" className="text-white text-decoration-none">
                  Mission & Vision
                </Link>
              </li>
              <li className="mb-2">
                <Link to="/team" className="text-white text-decoration-none">
                  Team
                </Link>
              </li>
            </ul>
          </div>

          {/* Legal Column */}
          <div className="col-sm-6 col-lg-2">
            <h5 style={{ color: "#D4BC8B" }} className="mb-3">
              Legal
            </h5>
            <ul className="list-unstyled mb-0">
              <li className="mb-2">
                <Link to="/privacy" className="text-white text-decoration-none">
                  Privacy Policy
                </Link>
              </li>
              <li className="mb-2">
                <Link to="/terms" className="text-white text-decoration-none">
                  Terms & Conditions
                </Link>
              </li>
            </ul>
          </div>
        </div>

        {/* Newsletter Section */}
        <div className="mt-5">
          <h5 style={{ color: "#D4BC8B" }} className="mb-3">
            Subscribe to Our Newsletter
          </h5>
          <div className="row g-2">
            <div className="col-sm-8 col-md-6 col-lg-4">
              <div className="input-group">
                <input
                  type="email"
                  className="form-control border-0"
                  placeholder="Enter your email address"
                  aria-label="Email address"
                  style={{
                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                    color: "white",
                  }}
                />
                <button
                  className="btn"
                  type="button"
                  style={{ backgroundColor: "#FFFFFF", color: "#4D7745" }}
                >
                  Subscribe
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
