import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./Profile.module.css";
import defaultAvatar from "../../../images/avatar.jpeg";
import { Link } from "react-router-dom";
import {
  reset,
  updateAvatar,
  updatePassword,
  updateUser,
} from "../../../Features/UserSlice";
import { showErrorToast, showSuccessToast } from "../../../utils/Toastr";
import axiosInstance from "../../../utils/axiosConfig";

const Profile = () => {
  const [showNameModal, setShowNameModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const { user, isLoading, isSuccess, isError, message } = useSelector(
    (state) => state.user
  );

  // Form states
  const [newName, setNewName] = useState(user?.name || "");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    if (isError) {
      setPasswordError(message);
      showErrorToast(message);
    }

    if (isSuccess) {
      setShowPasswordModal(false);
      setShowNameModal(false);
      setOldPassword("");
      setNewPassword("");
      setConfirmPassword("");
      setPasswordError("");
      showSuccessToast(message || "Update successful!");
    }

    return () => {
      dispatch(reset());
    };
  }, [isSuccess, isError, message, dispatch]);

  const handleNameSubmit = async (e) => {
    e.preventDefault();

    if (!newName.trim()) {
      showErrorToast("Name cannot be empty");
      return;
    }

    const result = await dispatch(updateUser({ name: newName }));

    if (result.type === "user/updateUser/fulfilled") {
      showSuccessToast("Name updated successfully");
      setShowNameModal(false);
    }
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    setPasswordError("");

    const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{10,})/;

    if (!passwordRegex.test(newPassword)) {
      const error =
        "Password must be at least 10 characters long, contain a capital letter and a special character (!@#$%^&*)";
      setPasswordError(error);
      showErrorToast(error);
      return;
    }

    if (newPassword !== confirmPassword) {
      const error = "New passwords don't match";
      setPasswordError(error);
      showErrorToast(error);
      return;
    }

    const result = await dispatch(
      updatePassword({
        oldPassword,
        newPassword,
      })
    );

    if (result.type === "user/updateUserPassword/rejected") {
      showErrorToast(result.payload || "Password update failed");
      return;
    }

    if (result.type === "user/updateUserPassword/fulfilled") {
      showSuccessToast("Password updated successfully");
    }
  };

  const updateUname = (newName) => {
    if (!newName.trim()) {
      showErrorToast("Name cannot be empty");
      return;
    }
    dispatch(updateUser({ name: newName })).then((result) => {
      if (result.type === "user/updateUser/fulfilled") {
        showSuccessToast("Name updated successfully");
        setShowNameModal(false);
      }
    });
  };

  useEffect(() => {
    return () => {
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
      }
    };
  }, [previewUrl]);

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;
    const filePreview = URL.createObjectURL(file);
    setPreviewUrl(filePreview);
    try {
      await dispatch(updateAvatar(file)).unwrap();
      showSuccessToast("Avatar updated successfully");
    } catch (error) {
      console.error("Error updating avatar:", error);
      showErrorToast("Failed to update avatar");
    }
  };

  return (
    <div className={`container-fluid py-5 ${styles.profileContainer} ${styles.layout}`}>
      <div className="row justify-content-center">
        <div className="col-lg-8">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/Dashboard" className="text-decoration-none">
                  Home
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Profile
              </li>
            </ol>
          </nav>
          <div className={`card border-0 shadow ${styles.profileCard}`}>
            <div className="card-body p-5">
              <div className="text-center mb-5">
                <div className={styles.imageContainer}>
                  <img
                    src={
                      previewUrl ||
                      (user?.avatar
                        ? `${
                            process.env.REACT_APP_API_URL.split("/api/v1")[0]
                          }${user.avatar}`
                        : defaultAvatar)
                    }
                    alt="Profile"
                    className={`rounded-circle ${styles.profileImage}`}
                  />
                  <label className={styles.uploadButton}>
                    <input
                      type="file"
                      className="d-none"
                      accept="image/*"
                      onChange={handleImageChange}
                    />
                    <i className="fas fa-camera"></i>
                  </label>
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-md-6">
                  <div className={styles.detailsContainer}>
                    <label className={styles.label}>Name</label>
                    <div className="d-flex align-items-center">
                      <h5 className={styles.value}>{user?.name}</h5>
                      <button
                        className={`btn btn-link ms-2 ${styles.editButton}`}
                        onClick={() => setShowNameModal(true)}
                      >
                        <i className="fas fa-edit"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className={styles.detailsContainer}>
                    <label className={styles.label}>Email</label>
                    <div className="d-flex align-items-center">
                      <h5 className={styles.value}>{user?.email}</h5>
                      <span className="badge bg-light text-muted ms-2">
                        Cannot change
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="border-top pt-4">
                <button
                  className="btn btn-outline-primary"
                  onClick={() => setShowPasswordModal(true)}
                >
                  <i className="fas fa-key me-2"></i>
                  Change Password
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Name Update Modal */}
      <div
        className={`modal fade ${showNameModal ? "show" : ""}`}
        style={{ display: showNameModal ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className={`modal-content ${styles.modalContent}`}>
            <div className={`modal-header ${styles.modalHeader}`}>
              <h5 className="modal-title">Update Name</h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => setShowNameModal(false)}
              ></button>
            </div>
            <form onSubmit={handleNameSubmit}>
              <div className="modal-body">
                <div className="mb-3">
                  <label className={styles.label}>New Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={newName}
                    onChange={(e) => setNewName(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className={`modal-footer ${styles.modalFooter}`}>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setShowNameModal(false)}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={() => updateUname(newName)}
                >
                  Update Name
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* Password Update Modal */}
      <div
        className={`modal fade ${showPasswordModal ? "show" : ""}`}
        style={{ display: showPasswordModal ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className={`modal-content ${styles.modalContent}`}>
            <div className={`modal-header ${styles.modalHeader}`}>
              <h5 className="modal-title">Change Password</h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => setShowPasswordModal(false)}
              ></button>
            </div>
            <form onSubmit={handlePasswordSubmit}>
              <div className="modal-body">
                {passwordError && (
                  <div className="alert alert-danger">{passwordError}</div>
                )}
                {isSuccess && (
                  <div className="alert alert-success">
                    Password updated successfully!
                  </div>
                )}
                <div className="mb-3">
                  <label className={styles.label}>Current Password</label>
                  <input
                    type="password"
                    className="form-control"
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label className={styles.label}>New Password</label>
                  <input
                    type="password"
                    className="form-control"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                  />
                </div>
                <div className="mb-3">
                  <label className={styles.label}>Confirm New Password</label>
                  <input
                    type="password"
                    className="form-control"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className={`modal-footer ${styles.modalFooter}`}>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setShowPasswordModal(false)}
                  disabled={isLoading}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={isLoading}
                >
                  {isLoading ? "Updating..." : "Update Password"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* Modal Backdrop */}
      {(showNameModal || showPasswordModal) && (
        <div className={`modal-backdrop fade show ${styles.backdrop}`}></div>
      )}
    </div>
  );
};

export default Profile;
