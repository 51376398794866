import React from "react";
import UnderConstruction from "../../Unauthorized/UnderConstruction";

function Users() {
  return (
    <div>
      <UnderConstruction />
    </div>
  );
}

export default Users;
