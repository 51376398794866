import toastr from "toastr";
import "toastr/build/toastr.min.css";

// Configure toastr default options
toastr.options = {
  closeButton: true,
  debug: false,
  newestOnTop: false,
  progressBar: true,
  positionClass: "toast-top-right",
  preventDuplicates: true,
  onclick: null,
  showDuration: "300",
  hideDuration: "1000",
  timeOut: "5000",
  extendedTimeOut: "1000",
  showEasing: "swing",
  hideEasing: "linear",
  showMethod: "fadeIn",
  hideMethod: "fadeOut",
};

// Success toast notification
export const showSuccessToast = (message) => {
  toastr.success(message, "Success", {
    timeOut: 3000,
    closeButton: true,
    progressBar: true,
    positionClass: "toast-top-right",
  });
};

// Error toast notification
export const showErrorToast = (message) => {
  toastr.error(message, "Error", {
    timeOut: 4000,
    closeButton: true,
    progressBar: true,
    positionClass: "toast-top-right",
  });
};
