import React from "react";
import { Link, NavLink } from "react-router-dom";
import styles from "./Sidebar.module.css";
import logo from "../../../images/logo brown.png";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../../Features/UserSlice";
import { allNavItems } from "./NavItems";
const Sidebar = ({ isOpen, onToggle }) => {
  const { user } = useSelector((state) => state.user);
  const isMobile = window.innerWidth <= 768;
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logoutUser());
  };

  const navItems = allNavItems.filter((item) =>
    item.allowedRoles.includes(user?.role)
  );

  const sidebarClasses = `${styles.sidebar} ${
    !isOpen ? styles.collapsed : ""
  } ${isMobile && isOpen ? styles.open : ""}`;

  return (
    <>
      {isMobile && !isOpen && (
        <button className={styles.mobileToggle} onClick={onToggle}>
          <i className="fas fa-bars"></i>
        </button>
      )}
      <div className={sidebarClasses}>
        <div className={styles.sidebarHeader}>
          <div className={styles.logo}>
            <img src={logo} alt="Nabat Tech" className={styles.logoImage} />
          </div>
          <button
            className={styles.toggleButton}
            onClick={onToggle}
            aria-label="Toggle Sidebar"
          >
            <i className={`fas ${isOpen ? "fa-times" : "fa-bars"}`}></i>
          </button>
        </div>

        <nav className={styles.nav}>
          {navItems.map((item) => (
            <NavLink
              key={item.path}
              to={item.path}
              end={item.end}
              className={({ isActive }) =>
                `${styles.navItem} ${isActive ? styles.active : ""} text-reset`
              }
            >
              <i className={`${item.icon} ${styles.icon}`}></i>
              <span
                className={`${styles.label} ${!isOpen ? styles.hidden : ""}`}
              >
                {item.label}
              </span>
            </NavLink>
          ))}
        </nav>

        <div className={styles.sidebarFooter}>
          <div className={styles.divider}></div>
          <div className={styles.userInfo}>
            <div className={`${styles.avatar} `}>
              <Link to="/Dashboard/profile" className="text-reset">
                <i className="fas fa-user"></i>
              </Link>
            </div>
            <div
              className={`${styles.userDetails} ${
                !isOpen ? styles.hidden : ""
              }`}
            >
              <span className={styles.userName}>{user.name}</span>
              <button className={styles.logoutButton} onClick={handleLogout}>
                <i className="fas fa-sign-out-alt"></i>
                <span className={!isOpen ? styles.hidden : ""}>Logout</span>
              </button>
            </div>
          </div>
        </div>

        {isMobile && isOpen && (
          <div className={styles.overlay} onClick={onToggle}></div>
        )}
      </div>
    </>
  );
};

export default Sidebar;
