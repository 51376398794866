import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { verifyOTP, reset } from "../../../Features/UserSlice";
import styles from "./styles.module.css";
const OTP = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isLoading, isSuccess, isError, message, user } = useSelector(
    (state) => state.user
  );

  const email = location.state?.email;
  const name = location.state?.name;
  const password = location.state?.password;

  const [otp, setOtp] = useState(["", "", "", "", "", ""]);

  useEffect(() => {
    if (!email) {
      navigate("/register");
      return;
    }
  }, [email, navigate, dispatch]);

  useEffect(() => {
    if (user) {
      const timer = setTimeout(() => {
        navigate("/login");
      }, 20);
      return () => clearTimeout(timer);
    }
  }, [user, navigate]);

  const handleChange = (element, index) => {
    const value = element.value;
    if (value.length > 1) return;

    setOtp((prev) => {
      const newOtp = [...prev];
      newOtp[index] = value;
      return newOtp;
    });

    // Move to next input if value is entered
    if (value && index < 5) {
      const nextInput = document.querySelector(`input[name=otp-${index + 1}]`);
      if (nextInput) {
        nextInput.focus();
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const otpString = otp.join("");

    if (otpString.length === 6) {
      dispatch(verifyOTP({ email, otp: otpString }));
    }
  };

  document.title = "Verify Email";

  return (
    <div className={`container-fluid p-0 ${styles.otpContainer}`}>
      <div className={`${styles.otpCard}`}>
        <div className={styles.otpHeader}>
          <h3 className={styles.otpTitle}>
            <i className="bi bi-envelope-check me-2"></i>
            Verify Your Email
          </h3>
        </div>
        <div className={styles.otpBody}>
          <div className={styles.emailInfo}>
            <p className={styles.infoText}>
              We've sent a verification code to:
            </p>
            <h5 className={styles.emailText}>{email}</h5>
          </div>

          {message && (
            <div
              className={`${styles.alert} ${
                isError ? styles.alertError : styles.alertSuccess
              }`}
            >
              <i
                className={`bi ${
                  isError ? "bi-exclamation-circle" : "bi-check-circle"
                } me-2`}
              ></i>
              {message}
            </div>
          )}

          <form onSubmit={handleSubmit}>
            <div className={styles.otpInputGroup}>
              {otp.map((data, index) => (
                <input
                  key={index}
                  type="text"
                  name={`otp-${index}`}
                  maxLength="1"
                  value={data}
                  onChange={(e) => handleChange(e.target, index)}
                  className={styles.otpInput}
                  disabled={isLoading}
                  autoComplete="off"
                  autoCapitalize="off"
                />
              ))}
            </div>

            <button
              type="submit"
              className={styles.submitButton}
              disabled={isLoading || otp.join("").length !== 6}
            >
              {isLoading ? (
                <div className="d-flex align-items-center justify-content-center">
                  <span
                    className="spinner-border spinner-border-sm me-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Verifying...
                </div>
              ) : (
                <>
                  <i className="bi bi-shield-check me-2"></i>
                  Verify Email
                </>
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default OTP;
