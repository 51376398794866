import { configureStore } from "@reduxjs/toolkit";
import UserReducer from "./Features/UserSlice";
import CategoryReducer from "./Features/CategorySlice";

export const store = configureStore({
  reducer: {
    user: UserReducer,
    category: CategoryReducer,
  },
});
