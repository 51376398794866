import React from "react";
import styles from "../Dashboard.module.css";

export const OrdersTable = () => {
  const orders = [
    {
      name: "Alwaleed",
      order: "50 OMR",
      orderDate: "12/10/2024",
      items: "Flower...",
      note: "Give more Flowers",
      status: "Completed",
    },
  ];

  return (
    <div className={styles.tableContainer}>
      <div className={styles.tableControls}>
        <div className={styles.search}>
          <input
            type="text"
            placeholder="Search"
            className={styles.searchInput}
          />
        </div>
        <div className={styles.dateFilters}>
          <input
            type="date"
            className={styles.dateInput}
            placeholder="Start Date"
          />
          <input
            type="date"
            className={styles.dateInput}
            placeholder="End Date"
          />
        </div>
      </div>

      <table className={styles.table}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Order</th>
            <th>Order Date</th>
            <th>Items</th>
            <th>Note</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order, index) => (
            <tr key={index}>
              <td>{order.name}</td>
              <td>{order.order}</td>
              <td>{order.orderDate}</td>
              <td>{order.items}</td>
              <td>{order.note}</td>
              <td>
                <span
                  className={`${styles.status} ${
                    styles[order.status.toLowerCase()]
                  }`}
                >
                  {order.status}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
