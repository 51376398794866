import React from "react";

const UnderConstruction = () => {
  return (
    <div className="d-flex min-vh-100 flex-column align-items-center justify-content-center bg-light">
      <div
        className="card shadow p-4 text-center"
        style={{ maxWidth: "400px" }}
      >
        <div className="mb-4">
          <div
            className="bg-warning rounded-circle d-inline-flex align-items-center justify-content-center"
            style={{ width: "80px", height: "80px" }}
          >
            <i className="fas fa-tools fs-2"></i>
          </div>
        </div>
        <h1 className="h4 fw-bold text-dark mb-3">Page Under Construction</h1>
        <p className="text-muted mb-4">
          We're working hard to bring this page to life. Check back soon!
        </p>
        <button
          onClick={() => window.history.back()}
          className="btn btn-primary"
        >
          Go Back
        </button>
      </div>
    </div>
  );
};

export default UnderConstruction;
